import React, { ReactNode, useState } from 'react';
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { ListItemText } from '@mui/material';
import classNames from 'classnames';
import styles from './SelectMultiple.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
        },
    },
    MenuListProps: {
        style: {
            paddingBottom: 0,
        },
    },
    anchorOrigin: {
        vertical: 'bottom' as const,
        horizontal: 'left' as const,
    },
    transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const,
    },
    getcontentanchorel: null,
    classes: {
        list: styles.list,
    },
};

export type SelectMultipleOption = {
    value: string;
    label: ReactNode;
    isDisabled?: boolean;
};

type SelectProps = {
    options: SelectMultipleOption[];
    onChange: (value: string[]) => void;
    values: string[];
    label?: string;
    className?: string;
    width?: number | string;
    optionsWidth?: number | string;
    onClose?: () => void;
    onOpen?: () => void;
    noSelectionText?: string;
    showSelectAll?: boolean;
    showDeselectAll?: boolean;
    headerContent?: ReactNode;
    footerContent?: ReactNode;
    disabled?: boolean;
};

const SelectMultiple = (props: SelectProps) => {
    const {
        options,
        onChange,
        values,
        label,
        width = 150,
        optionsWidth,
        onClose,
        onOpen,
        noSelectionText = 'None selected',
        showSelectAll = true,
        showDeselectAll = false,
        headerContent,
        footerContent,
        disabled = false,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        onChange(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleClose = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const handleOpen = () => {
        setIsOpen(true);
        if (onOpen) {
            onOpen();
        }
    };

    const containerClassNames = classNames(styles.selectContainer, props.className);
    const deselectAllClassNames = classNames(styles.deselectAll, {
        [styles.deselectAllDisabled]: !values.length,
    });

    return (
        <div className={containerClassNames}>
            <FormControl size='small' sx={{ width }}>
                {label && <InputLabel id='select-multiple'>{label}</InputLabel>}
                <MUISelect
                    labelId='select-multiple'
                    multiple
                    value={values}
                    onChange={handleChange}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    input={<OutlinedInput label={label} />}
                    renderValue={selected =>
                        !selected.length ? noSelectionText : `${selected.length} ${selected.length > 1 ? 'values' : 'value'} selected`
                    }
                    displayEmpty
                    MenuProps={menuProps}
                    open={isOpen}
                    fullWidth
                    disabled={disabled}
                >
                    {headerContent && <div className={styles.headerContent}>{headerContent}</div>}
                    {options.map((option, index) => (
                        <MenuItem
                            key={index}
                            value={option.value}
                            style={{ backgroundColor: 'white', width: optionsWidth }}
                            disabled={option.isDisabled}
                        >
                            <Checkbox checked={values.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                    {(showSelectAll || showDeselectAll || footerContent) && (
                        <div className={styles.selectFooter}>
                            {showSelectAll && (
                                <span className={styles.selectAll} onClick={() => onChange(options.map(option => option.value))}>
                                    Select all
                                </span>
                            )}

                            {showDeselectAll && (
                                <span className={deselectAllClassNames} onClick={() => onChange([])}>
                                    Deselect all
                                </span>
                            )}

                            {footerContent && <span className={styles.footerContent}>{footerContent}</span>}
                        </div>
                    )}
                </MUISelect>
            </FormControl>
        </div>
    );
};

export default SelectMultiple;
