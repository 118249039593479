import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { SamplingEvent, SamplingKit, SamplingEventBatchAmendmentOwner } from 'src/shared/types';
import { isEqual } from 'lodash';
import {
    samplingEventQuery,
    SamplingEventQueryResult,
    updateSampleKitQuery,
    initiateSampleManifestEventQuery,
    submitSampleKitsForProcessingQuery,
    batchApprovedByLabQuery,
    batchApprovedByLogisticsQuery,
    batchAmendmentRequiredQuery,
    lockEditingSampleKitQuery,
    unlockEditingSampleKitQuery,
} from './queries/sampleManifestQueries';
import { defaultSamplingKit } from '../sampleManifestSlice';

type SamplingEventQueryParams = {
    samplingEventId: string;
    projectId: string;
};

type UpdateSamplingKitParams = {
    projectId: string;
    samplingEventId: string;
    input: SamplingKit;
};

type SubmitSampleKitsForProcessingParams = {
    projectId: string;
    samplingEventId: string;
    kitIds: string[];
    eventCompleted: boolean;
    batchId?: string | null;
};

type BatchApprovedByLogisticsParams = {
    projectId: string;
    samplingEventId: string;
    batchId: string;
};

type BatchAmendmentRequiredParams = BatchApprovedByLogisticsParams & {
    message: string;
    owner: SamplingEventBatchAmendmentOwner;
};

type LockEditingSampleKitParams = {
    projectId: string;
    eventIdentifier: string;
    kitId: string;
};

type LockEditingSampleKitResponse = {
    lock: boolean;
    event: SamplingEvent;
};

const sampleManifestGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        samplingEvent: builder.query<SamplingEventQueryResult, SamplingEventQueryParams>({
            query: params => ({
                body: samplingEventQuery,
                variables: params,
            }),
            transformResponse(baseQueryReturnValue) {
                const samplingEvent = baseQueryReturnValue.sampleManifest.samplingEvent.event;
                // Add a default value for all the kits
                const kits = (samplingEvent as SamplingEvent).sampleManifestInfo.kits.map(kit => {
                    const samplingKit = Object.fromEntries(Object.entries(kit).filter(([, value]) => value));
                    return { ...defaultSamplingKit, ...samplingKit };
                });

                return {
                    ...samplingEvent,
                    kits,
                };
            },
        }),
        updateSampleKit: builder.mutation<boolean, UpdateSamplingKitParams>({
            query: params => ({
                body: updateSampleKitQuery,
                variables: params,
            }),
            async onQueryStarted(arg, api) {
                const { queryFulfilled, dispatch } = api;

                const patchSamplingEvent = dispatch(
                    sampleManifestGraphSlice.util.updateQueryData(
                        'samplingEvent',
                        { samplingEventId: arg.samplingEventId, projectId: arg.projectId },
                        (draft: SamplingEventQueryResult) => {
                            if (draft) {
                                const kitIndex = draft.sampleManifestInfo.kits.findIndex(kit => kit.id === arg.input.id);
                                if (kitIndex !== -1) {
                                    draft.sampleManifestInfo.kits[kitIndex] = { ...defaultSamplingKit, ...arg.input };
                                }
                            }
                        }
                    )
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    patchSamplingEvent.undo();
                    console.error('Error updating sampling kit:', error);
                }
            },
        }),

        initiateSampleManifestEvent: builder.mutation<boolean, SamplingEventQueryParams>({
            query: params => ({
                body: initiateSampleManifestEventQuery,
                variables: params,
            }),
        }),

        submitSampleKitsForProcessing: builder.mutation<string, SubmitSampleKitsForProcessingParams>({
            query: params => ({
                body: submitSampleKitsForProcessingQuery,
                variables: params,
            }),
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue?.sampleManifest?.submitSampleKitsForProcessing?.batchId || '';
            },
        }),

        batchApprovedByLogistics: builder.mutation<boolean, BatchApprovedByLogisticsParams>({
            query: params => ({
                body: batchApprovedByLogisticsQuery,
                variables: params,
            }),
        }),

        batchApprovedByLab: builder.mutation<boolean, BatchApprovedByLogisticsParams>({
            query: params => ({
                body: batchApprovedByLabQuery,
                variables: params,
            }),
        }),
        batchAmendmentRequired: builder.mutation<boolean, BatchAmendmentRequiredParams>({
            query: params => ({
                body: batchAmendmentRequiredQuery,
                variables: params,
            }),
        }),

        /*
            Tries to lock the kit for editing. lock will be true if the kit is successfully locked.
            Also, updates the local cache with the new kits data.
        */
        lockEditingSampleKit: builder.mutation<LockEditingSampleKitResponse, LockEditingSampleKitParams>({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    body: lockEditingSampleKitQuery,
                    variables: {
                        in: arg,
                    },
                });

                const data = result.data?.sampleManifest?.lockEditingSampleKit as LockEditingSampleKitResponse;

                const { dispatch } = api;
                if (data) {
                    dispatch(
                        sampleManifestGraphSlice.util.updateQueryData(
                            'samplingEvent',
                            { samplingEventId: arg.eventIdentifier, projectId: arg.projectId },
                            (draft: SamplingEventQueryResult) => {
                                if (draft) {
                                    // Replace each kit if they have different values using isEqual
                                    for (const kit of data.event.sampleManifestInfo.kits) {
                                        const existingKitIndex = draft.sampleManifestInfo.kits.findIndex(k => k.id === kit.id);
                                        if (existingKitIndex !== -1 && !isEqual(draft.sampleManifestInfo.kits[existingKitIndex], kit)) {
                                            draft.sampleManifestInfo.kits[existingKitIndex] = kit;
                                        }
                                    }
                                }
                            }
                        )
                    );
                }
                return { data: { lock: data?.lock, event: data?.event } };
            },
        }),

        /*
            Tries to unlock the kit from editing. lock will be false if the kit is successfully unlocked.
            Also, updates the local cache with the new kits data.
        */
        unlockEditingSampleKit: builder.mutation<LockEditingSampleKitResponse, LockEditingSampleKitParams>({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    body: unlockEditingSampleKitQuery,
                    variables: {
                        in: arg,
                    },
                });

                const data = result.data?.sampleManifest?.unlockEditingSampleKit as LockEditingSampleKitResponse;

                const { dispatch } = api;
                if (data) {
                    dispatch(
                        sampleManifestGraphSlice.util.updateQueryData(
                            'samplingEvent',
                            { samplingEventId: arg.eventIdentifier, projectId: arg.projectId },
                            (draft: SamplingEventQueryResult) => {
                                if (draft) {
                                    // Replace each kit if they have different values using isEqual
                                    for (const kit of data.event.sampleManifestInfo.kits) {
                                        const existingKitIndex = draft.sampleManifestInfo.kits.findIndex(k => k.id === kit.id);
                                        if (existingKitIndex !== -1 && !isEqual(draft.sampleManifestInfo.kits[existingKitIndex], kit)) {
                                            draft.sampleManifestInfo.kits[existingKitIndex] = kit;
                                        }
                                    }
                                }
                            }
                        )
                    );
                }
                return { data: { lock: data?.lock, event: data?.event } };
            },
        }),
    }),
});

export const {
    useSamplingEventQuery,
    useLazySamplingEventQuery,
    useUpdateSampleKitMutation,
    useInitiateSampleManifestEventMutation,
    useSubmitSampleKitsForProcessingMutation,
    useBatchApprovedByLogisticsMutation,
    useBatchApprovedByLabMutation,
    useBatchAmendmentRequiredMutation,
    useLockEditingSampleKitMutation,
    useUnlockEditingSampleKitMutation,
} = sampleManifestGraphSlice;
export const { samplingEvent: fetchSamplingEvent } = sampleManifestGraphSlice.endpoints;
export default sampleManifestGraphSlice.reducer;
