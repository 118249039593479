import React from 'react';
import { useSearchParams } from 'react-router-dom';
import usePermissions from 'src/shared/hooks/usePermissions';
import { Resources, Access } from 'src/shared/types';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import BackNextNavigation from 'src/shared/components/back-next-navigation/BackNextNavigation';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { ReactComponent as WarningCircleIcon } from 'src/assets/svg/general/warning-circle.svg';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import { SampleManifestFormViews, sampleManifestFormViewSettings } from '../../SampleManifestForm';
import SubmitData from '../submit-data/SubmitData';

const SampleManifestBackNextNavigation = () => {
    const [searchParams] = useSearchParams();
    const { hasPermission } = usePermissions();
    const viewFromUrl = searchParams.get('view');
    const samplingEventId = searchParams.get('samplingEventId') as string;
    const batchId = searchParams.get('batchId') as string;
    const navigation = useAppNavigation();
    const {
        kitsReadyToAddTests,
        readyToSubmitKits,
        isBatchOnlySubmit,
        hasIncompleteSamplesInBatch,
        hasIncompleteTestTypesInBatch,
        refetchSamplingEvent,
    } = useSamplingEvent();
    const hasPermissionToAddTests = hasPermission({
        to: Access.VIEW,
        resource: Resources.SAMPLE_MANIFEST_ADD_TESTS,
    });

    const hasPermissionToSubmit = hasPermission({
        to: Access.VIEW,
        resource: Resources.SAMPLE_MANIFEST_SUBMIT,
    });

    const currentStep = sampleManifestFormViewSettings[viewFromUrl as SampleManifestFormViews]?.step ?? 0;

    const nextFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep + 1
    );
    const previousFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep - 1
    );

    const totalSteps = Object.keys(sampleManifestFormViewSettings).length;

    const shouldDisableNext = () => {
        const isAddTestsPage = nextFormView === SampleManifestFormViews.ADD_TESTS;
        const isSubmitPage = nextFormView === SampleManifestFormViews.SUBMIT;
        const isLastStep = currentStep === totalSteps - 1;

        const noKitsReadyToAddTests = kitsReadyToAddTests.length === 0;
        const hasIncompleteSamples = hasIncompleteSamplesInBatch;
        const noKitsReadyToSubmit = readyToSubmitKits.length === 0;
        const hasIncompleteTestTypes = hasIncompleteTestTypesInBatch;

        const disableAddTestsPageConditons = [
            isAddTestsPage,
            (noKitsReadyToAddTests && !isBatchOnlySubmit) || (isBatchOnlySubmit && hasIncompleteSamples),
        ];
        const disableAddTestsPage = disableAddTestsPageConditons.every(Boolean) || !hasPermissionToAddTests;

        const disableSubmitPageConditons = [
            isSubmitPage,
            (noKitsReadyToSubmit && !isBatchOnlySubmit) || (isBatchOnlySubmit && hasIncompleteTestTypes),
        ];
        const disableSubmitPage = disableSubmitPageConditons.every(Boolean) || !hasPermissionToSubmit;

        return disableAddTestsPage || disableSubmitPage || isLastStep;
    };

    const shouldDisableNextButton = shouldDisableNext();
    const shouldHideNext = currentStep === totalSteps - 1;
    const nextTooltip = !hasPermissionToAddTests ? (
        <div className='flex gap-4'>
            <div className='h-4 w-4'>
                <WarningCircleIcon />
            </div>
            <div>
                <CMSArticle slug='dsm-disabled-form-step-message' />
            </div>
        </div>
    ) : null;

    const onBack = () => {
        if (previousFormView) {
            navigation.toSampleManifestForm({
                view: previousFormView as SampleManifestFormViews,
                samplingEventId,
                batchId,
            });
        } else {
            navigation.toSampleManifest();
        }
        refetchSamplingEvent();
    };

    const onNext = () => {
        if (nextFormView) {
            navigation.toSampleManifestForm({
                view: nextFormView as SampleManifestFormViews,
                samplingEventId,
                batchId,
            });
        }
        refetchSamplingEvent();
    };

    return (
        <div className='flex gap-2'>
            <BackNextNavigation
                onBack={onBack}
                onNext={onNext}
                disableNext={shouldDisableNextButton}
                hideNext={shouldHideNext}
                nextTooltip={nextTooltip}
            />

            {shouldHideNext && <SubmitData />}
        </div>
    );
};

export default SampleManifestBackNextNavigation;
