import React, { ReactElement, ReactNode } from 'react';
import type { TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import useDataTable from 'src/shared/components/data-table/hooks/useDataTable';
import styles from 'src/shared/components/data-table/DataTable.module.scss';
import classNames from 'classnames';

const TableRow = (props: { rowData: TableRowData; rowId: string | number }) => {
    const { columns, hiddenColumnIds, selectedRowIds, onRowClick } = useDataTable();
    const cells = props.rowData;
    const uniqueColumnIndex = columns.findIndex(column => column.isUnique);
    const uniqueRowId = cells[uniqueColumnIndex] as string;
    const formattedUniqueRowId = React.isValidElement(uniqueRowId)
        ? ((uniqueRowId as ReactElement).props.children as string)
        : (uniqueRowId as string);
    const rowClassName = classNames(styles.row, {
        [styles.rowSelected]: selectedRowIds && selectedRowIds.has(formattedUniqueRowId),
    });

    return (
        <tr className={rowClassName} onClick={() => onRowClick?.(props.rowData)}>
            {cells?.map((cellData, index) => {
                const currentColumn = columns[index];
                if ((hiddenColumnIds && hiddenColumnIds.has(currentColumn?.columnId)) || !currentColumn) {
                    return null;
                }

                const { renderer, bodyCellClassName } = currentColumn;

                const Contents = () => {
                    if (typeof cellData === 'string' && cellData.startsWith('<')) {
                        // If cellData starts with '<', assume it's HTML code
                        return <td key={index} className={bodyCellClassName} dangerouslySetInnerHTML={{ __html: cellData }} />;
                    } else {
                        return (
                            <td key={index} className={bodyCellClassName}>
                                {renderer
                                    ? renderer({
                                          rowData: props.rowData,
                                          rowId: props.rowId,
                                          cellData,
                                      })
                                    : (cellData as ReactNode)}
                            </td>
                        );
                    }
                };

                return <Contents key={index} />;
            })}
        </tr>
    );
};

export default TableRow;
