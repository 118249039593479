import React from 'react';
import { ReactComponent as CompleteIcon } from 'src/assets/svg/general/complete.svg';
import { ReactComponent as NoneIcon } from 'src/assets/svg/general/none.svg';
import { ReactComponent as InProgressIcon } from 'src/assets/svg/general/in-progress.svg';
import { ProjectSampleManifestStatus } from 'src/shared/types';

type ProjectSampleManifestStatusIndicatorProps = {
    status: ProjectSampleManifestStatus;
};

export const statusConfig = {
    [ProjectSampleManifestStatus.NOT_STARTED]: { icon: <NoneIcon className='fill-grey-80' />, title: 'Not started', description: '' },
    [ProjectSampleManifestStatus.IN_PROGRESS_NO_DATA_SUBMITTED]: {
        icon: <InProgressIcon />,
        title: 'In Progress',
        description: 'No data submitted',
    },
    [ProjectSampleManifestStatus.IN_PROGRESS_MORE_SAMPLES_REQUIRED]: {
        icon: <InProgressIcon />,
        title: 'In Progress',
        description: 'More samples expected',
    },
    [ProjectSampleManifestStatus.IN_PROGRESS_ALL_SAMPLES_SUBMITTED]: {
        icon: <InProgressIcon />,
        title: 'In Progress',
        description: 'All samples submitted',
    },
    [ProjectSampleManifestStatus.COMPLETED_ALL_EVENTS_COMPLETE]: {
        icon: <CompleteIcon />,
        title: 'Complete',
        description: 'All events complete',
    },
    [ProjectSampleManifestStatus.COMPLETED_FUTURE_EVENT_PLANNED]: {
        icon: <CompleteIcon />,
        title: 'Complete',
        description: 'Future events planned',
    },
    [ProjectSampleManifestStatus.COMPLETED_NO_FUTURE_EVENT_PLANNED]: {
        icon: <CompleteIcon />,
        title: 'Complete',
        description: 'No future events planned',
    },
};

const ProjectSampleManifestStatusIndicator = (props: ProjectSampleManifestStatusIndicatorProps) => {
    const { status } = props;
    if (!statusConfig[status]) {
        return null;
    }
    return (
        <div className='flex items-center gap-1 px-2'>
            <div>{statusConfig[status].icon}</div>
            <div className='flex flex-col text-sm'>
                <span>{statusConfig[status].title}</span>
                {statusConfig[status].description && <span className='text-grey-80'>{statusConfig[status].description}</span>}
            </div>
        </div>
    );
};

export default ProjectSampleManifestStatusIndicator;
