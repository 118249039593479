import { gql } from 'graphql-request';
import { SamplingEvent } from 'src/shared/types';

export const samplingEventQuery = gql`
    query samplingEvent($projectId: String!, $samplingEventId: String!) {
        sampleManifest {
            samplingEvent(projectId: $projectId, identifier: $samplingEventId) {
                event {
                    name
                    fromDate
                    toDate
                    year
                    identifier
                    processed
                    processedDate
                    status
                    sampleManifestInfo {
                        batches {
                            id
                            kits
                            amendmentMessage
                            status
                            createdAt
                            logisticsApprovalAt
                            labApprovalAt
                        }
                        kits {
                            id
                            name
                            barcode
                            latitude
                            longitude
                            sampler
                            sampledAt
                            sampledTime
                            habitat
                            notes
                            volumeFiltered
                            combinedVolumeFiltered
                            noOfSubSamples
                            preservationMethod
                            collectionMethod
                            samplingDuration
                            testTypes {
                                habitatName
                                assayName
                                habitatAssayKey
                            }
                            status
                        }
                        kitsSentAt
                        countInfo {
                            kitsCount
                            countByTestTypes {
                                type {
                                    habitatName
                                    assayName
                                    habitatAssayKey
                                }
                                count
                            }
                        }
                    }
                }
            }
        }
    }
`;

export type SamplingEventQueryResult = SamplingEvent;

export const updateSampleKitQuery = gql`
    mutation updateSampleKit($projectId: String!, $samplingEventId: String!, $input: SampleKitInput!) {
        sampleManifest {
            updateSampleKit(input: $input, projectId: $projectId, eventIdentifier: $samplingEventId)
        }
    }
`;

export const initiateSampleManifestEventQuery = gql`
    mutation initiateSampleManifestEvent($projectId: String!, $samplingEventId: String!) {
        sampleManifest {
            initiateSampleManifestForEvent(projectId: $projectId, eventIdentifier: $samplingEventId)
        }
    }
`;

export const submitSampleKitsForProcessingQuery = gql`
    mutation submitSampleKitsForProcessing(
        $projectId: String!
        $samplingEventId: String!
        $kitIds: [String!]!
        $eventCompleted: Boolean!
        $batchId: String
    ) {
        sampleManifest {
            submitSampleKitsForProcessing(
                projectId: $projectId
                eventIdentifier: $samplingEventId
                kits: $kitIds
                eventCompleted: $eventCompleted
                batchId: $batchId
            ) {
                eventCompleted
                batchId
            }
        }
    }
`;

export const batchApprovedByLogisticsQuery = gql`
    mutation approvedByLogisticsForBatch($projectId: String!, $samplingEventId: String!, $batchId: String!) {
        sampleManifest {
            approvedByLogisticsForBatch(projectId: $projectId, eventIdentifier: $samplingEventId, batchId: $batchId)
        }
    }
`;

export const batchApprovedByLabQuery = gql`
    mutation approvedByLabForBatch($projectId: String!, $samplingEventId: String!, $batchId: String!) {
        sampleManifest {
            approvedByLabForBatch(projectId: $projectId, eventIdentifier: $samplingEventId, batchId: $batchId)
        }
    }
`;

export const batchAmendmentRequiredQuery = gql`
    mutation amendmentRequiredForBatch(
        $projectId: String!
        $samplingEventId: String!
        $batchId: String!
        $message: String!
        $owner: AmendmentParty
    ) {
        sampleManifest {
            amendmentRequiredForBatch(
                projectId: $projectId
                eventIdentifier: $samplingEventId
                batchId: $batchId
                message: $message
                owner: $owner
            )
        }
    }
`;

export const lockEditingSampleKitQuery = gql`
    mutation lockEditingSampleKit($in: SampleById!) {
        sampleManifest {
            lockEditingSampleKit(in: $in) {
                lock
                event {
                    sampleManifestInfo {
                        kits {
                            id
                            name
                            barcode
                            latitude
                            longitude
                            sampler
                            sampledAt
                            sampledTime
                            habitat
                            notes
                            volumeFiltered
                            combinedVolumeFiltered
                            noOfSubSamples
                            preservationMethod
                            collectionMethod
                            samplingDuration
                            testTypes {
                                habitatName
                                assayName
                                habitatAssayKey
                            }
                            status
                        }
                    }
                }
            }
        }
    }
`;

export const unlockEditingSampleKitQuery = gql`
    mutation unlockEditingSampleKit($in: SampleById!) {
        sampleManifest {
            unlockEditingSampleKit(in: $in) {
                lock
                event {
                    sampleManifestInfo {
                        kits {
                            id
                            name
                            barcode
                            latitude
                            longitude
                            sampler
                            sampledAt
                            sampledTime
                            habitat
                            notes
                            volumeFiltered
                            combinedVolumeFiltered
                            noOfSubSamples
                            preservationMethod
                            collectionMethod
                            samplingDuration
                            testTypes {
                                habitatName
                                assayName
                                habitatAssayKey
                            }
                            status
                        }
                    }
                }
            }
        }
    }
`;
