import React from 'react';
import { PrimaryButton } from 'src/shared/components/button/Button';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import { SamplingEventStatus, Resources, Access } from 'src/shared/types';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { useAppSelector, useAppDispatch } from 'src/store';
import usePermissions from 'src/shared/hooks/usePermissions';
import { selectSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSlice';
import AmendmentContent from './AmendmentContent';
import PrintBatchButton from './PrintBatchButton';
import { SampleManifestFormViews } from '../../sample-manifest-form/SampleManifestForm';
import { getCurrentSampleManifestState } from '../../state/sampleManifestSelector';

const StartPageBanner = () => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermissions();
    const { selectedSamplingEventId } = useAppSelector(getCurrentSampleManifestState);
    const { isEventInProgress, onInitiateSampleManifest, eventStatus, refetchSamplingEvent } = useSamplingEvent({
        samplingEventId: selectedSamplingEventId,
    });

    const isEventCompleted = eventStatus === SamplingEventStatus.COMPLETED;

    const hasSubmittedAllSamples = eventStatus === SamplingEventStatus.SUBMITTED;

    const hasPermissionToStartEvent = hasPermission({
        to: Access.UPDATE,
        resource: Resources.SAMPLE_MANIFEST_ADD_DATA,
    });

    const onStartSamplingEvent = async () => {
        if (!isEventInProgress) {
            await onInitiateSampleManifest(selectedSamplingEventId);
            await refetchSamplingEvent();
        }
        navigation.toSampleManifestForm({ view: SampleManifestFormViews.ADD_FIELD_DATA, samplingEventId: selectedSamplingEventId });
    };

    const onResumeSamplingEvent = () => {
        dispatch(selectSamplingKit(''));
        navigation.toSampleManifestForm({ view: SampleManifestFormViews.ADD_FIELD_DATA, samplingEventId: selectedSamplingEventId });
    };

    const onViewSamplingEventSubmission = () => {
        navigation.toSampleManifestView({ samplingEventId: selectedSamplingEventId });
    };

    const getContent = () => {
        if (hasSubmittedAllSamples) {
            return (
                <>
                    <div className='flex flex-col gap-2 '>
                        <div className='text-primary flex text-lg font-bold'>You have submitted all samples for this event.</div>
                        NatureMetrics is currently reviewing these samples.
                        <br />
                        Once NatureMetrics has accepted the samples, this event will be shown as complete.
                    </div>
                    <div className='flex flex-col gap-2'>
                        <PrintBatchButton />
                        <PrimaryButton onClick={onViewSamplingEventSubmission}>View sampling data &gt;</PrimaryButton>
                    </div>
                </>
            );
        }

        if (isEventInProgress) {
            return (
                <>
                    <div className='flex flex-col gap-2'>
                        <div className='text-primary flex text-lg font-bold'>This event is in progress</div>
                        <div>Click ‘resume sampling event’ to continue the submission of your project sampling data</div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <PrintBatchButton />
                        <PrimaryButton onClick={onResumeSamplingEvent} disabled={!hasPermissionToStartEvent}>
                            Resume sampling event &gt;
                        </PrimaryButton>
                    </div>
                </>
            );
        }

        if (isEventCompleted) {
            return (
                <>
                    <div className='flex flex-col gap-2'>
                        <div className='text-primary flex text-lg font-bold'>This event is complete.</div>
                        <div>NatureMetrics has accepted all of your samples. We are currently processing your results.</div>

                        <div>
                            We will contact you by email when your report is ready for download from the NatureMetrics Intelligence Platform
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <PrintBatchButton />
                        <PrimaryButton onClick={onViewSamplingEventSubmission}>View sampling data &gt;</PrimaryButton>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className='flex flex-col gap-2'>
                    <div className='text-primary flex text-lg font-bold'>Ready to start?</div>
                    Once you are ready to begin adding sampling data for this event, click the button on the right to begin the event.
                    <br />
                    After clicking this button, you are ready to being collecting field data for this event
                </div>
                <div className='content-end'>
                    <PrimaryButton onClick={onStartSamplingEvent} disabled={!selectedSamplingEventId || !hasPermissionToStartEvent}>
                        Start sampling event &gt;
                    </PrimaryButton>
                </div>
            </>
        );
    };

    return (
        <div className='flex flex-col gap-2'>
            <div className='border-primary border-2 shadow-lg p-4 rounded-lg bg-white flex gap-6 justify-between'>{getContent()}</div>
            <AmendmentContent />
        </div>
    );
};

export default StartPageBanner;
