export enum Resources {
    USER = 'user',
    PROJECT_DEFINITION = 'project_definition',
    PLATFORM_PAGE = 'platform_page',
    HABITAT_INSIGHTS_PAGE = 'hlca',
    SURVEY_DESIGN = 'survey_design',
    REPORT = 'report',
    SAMPLE_RECEPTION = 'sample_reception',
    SAMPLE_MANIFEST = 'sm_page',
    SAMPLE_MANIFEST_ADD_DATA = 'sm_add_data',
    SAMPLE_MANIFEST_ADD_TESTS = 'sm_add_tests',
    SAMPLE_MANIFEST_SUBMIT = 'sm_submit_for_processing',
}
