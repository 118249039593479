import React from 'react';
import { useSearchParams } from 'react-router-dom';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import useProjectSummary from 'src/shared/hooks/useProjectSummary';
import { SecondaryButton } from 'src/shared/components/button/Button';
import { ReactComponent as BackArrowIcon } from 'src/assets/svg/arrows/back-arrow.svg';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import Overview from 'src/app/samples/sample-reception/views/view-sample-manifest/components/overview/Overview';
import ViewSampleManifestContent from './components/ViewSampleManifestContent';

const title = 'Submitted samples';
const SampleManifestView = () => {
    const navigation = useAppNavigation();
    const { projectSummary } = useProjectSummary();
    const [searchParams] = useSearchParams();
    const samplingEventIdFromUrl = searchParams.get('samplingEventId') as string;
    const { samplingEvent } = useSamplingEvent({ samplingEventId: samplingEventIdFromUrl });

    const mainContent = (
        <div className='flex flex-col gap-8 overflow-y-auto'>
            <Overview showInfo={false} />
            <ViewSampleManifestContent samplingEventId={samplingEventIdFromUrl} />
        </div>
    );

    const breadcrumbs = [
        {
            label: 'Sample Manifest',
            callback: navigation.toSampleManifest,
        },
        {
            label: `${projectSummary?.projectName}(${samplingEvent?.name})`,
        },
    ];

    const otherActions = (
        <SecondaryButton onClick={navigation.toSampleManifest} className='flex gap-2'>
            {' '}
            <BackArrowIcon /> Back to sample manifest home page
        </SecondaryButton>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            title={title}
            subTitle='View submitted project sampling data'
            breadcrumbs={breadcrumbs}
            otherActions={otherActions}
        />
    );
};

export default SampleManifestView;
