import React from 'react';
import Modal from 'src/shared/components/modal/Modal';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { ReactComponent as ErrorIcon } from 'src/assets/svg/icon-err-dark.svg';

type SubmitionErrorModalProps = {
    onClose: () => void;
    errorMessage: string;
};

const SubmitionErrorModal = (props: SubmitionErrorModalProps) => {
    const { onClose, errorMessage } = props;
    return (
        <Modal onClose={onClose} dismissOnClickOutside={true}>
            <div className='flex flex-col gap-6'>
                <div className='flex gap-2 text-red-100'>
                    <ErrorIcon /> Error
                </div>
                <div className='text-grey-80 [&_hr]:my-2 [&_hr]:border-grey-40'>
                    {errorMessage ? (
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                    ) : (
                        <>
                            An error occurred while submitting your data.
                            <br />
                            <br />
                            Please try again later.
                        </>
                    )}
                </div>
                <div className='flex justify-end'>
                    <PrimaryButton className='w-[200px]' onClick={onClose}>
                        OK
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    );
};

export default SubmitionErrorModal;
