import React, { useEffect, useState } from 'react';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import MapOutlined from '@mui/icons-material/MapOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { formattedKitBarcode } from 'src/shared/helpers/kitHelpers';
import { SamplingEventBatchStatus } from 'src/shared/types';
import classNames from 'classnames';
import SamplingEventBatch from 'src/app/samples/components/sampling-event-batch/SamplingEventBatch';
import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import SamplingEventBatchStatusIndicator from 'src/shared/components/sampling-event-batch-status-indicator/SamplingEventBatchStatusIndicator';
import SampleMapModal from 'src/app/samples/components/sample-map-modal/SampleMapModal';
import { SecondaryButton } from 'src/shared/components/button/Button';
import SamplesCountInfo from 'src/app/samples/components/samples-count-info/SamplesCountInfo';
import RemainingTestTypes from './components/remaining-test-types/RemainingTestTypes';
import AddTestsDropdown from './components/add-tests-dropdown/AddTestsDropdown';
import styles from './AddTestsToSample.module.scss';
import TestColumn from './components/test-column/TestColumn';
import ReadyToSubmitColumn from './components/ready-to-submit-column/ReadyToSubmitColumn';

const AddTestsToSample = () => {
    const [isMapViewDisabled, setIsMapViewDisabled] = useState(true);
    const [isMapViewOpen, setIsMapViewOpen] = useState(false);
    const { hasKitsWithLatLongs } = useSamplingEvent();

    useEffect(() => {
        setIsMapViewDisabled(!hasKitsWithLatLongs);
    }, [hasKitsWithLatLongs]);

    return (
        <div className='flex flex-1 flex-col gap-2 overflow-y-auto'>
            <div className='flex justify-between border-b border-grey-40 pb-2'>
                <div className='flex flex-col gap-2'>
                    <div className='text-lg'>Add test(s)</div>
                    <div>You must add test(s) to samples before you can submit them</div>
                    <div className='flex gap-12 items-end pb-2 grow'>
                        <div>
                            <ViewListOutlinedIcon /> List view
                        </div>
                        <div>
                            <SecondaryButton onClick={() => setIsMapViewOpen(true)} disabled={isMapViewDisabled}>
                                <MapOutlined />
                                &nbsp;View samples on map
                            </SecondaryButton>
                        </div>
                        <div className='ml-auto'>
                            <SamplesCountInfo />
                        </div>
                    </div>
                </div>
                <RemainingTestTypes />
            </div>
            <div className='flex flex-1'>
                <MainContentList />
                {isMapViewOpen && <SampleMapModal showTestsInTootip={true} onClose={() => setIsMapViewOpen(false)} />}
            </div>
        </div>
    );
};

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
        width: '0px',
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
        width: '150px',
    },
    {
        columnId: 'kitBarcode',
        title: 'Kit Barcode',
        width: '150px',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
        width: '150px',
    },
    {
        columnId: 'addTests',
        title: 'Add Test(s)',
        renderer(params) {
            return (
                <AddTestsDropdown
                    samplingKitId={
                        React.isValidElement(params.rowData[0])
                            ? (params.rowData[0].props.children as string)
                            : (params.rowData[0] as string)
                    }
                />
            );
        },
        width: '200px',
    },
    {
        columnId: 'tests',
        title: 'Tests',
        width: '100%',
        renderer(params) {
            return (
                <TestColumn
                    kitId={
                        React.isValidElement(params.rowData[0])
                            ? (params.rowData[0].props.children as string)
                            : (params.rowData[0] as string)
                    }
                />
            );
        },
        titleSlug: 'sample-manifest-batch-table-tests-column',
    },
    {
        columnId: 'readyToSubmit',
        title: 'Ready to Submit',
        width: '140px',
        renderer(params) {
            return (
                <ReadyToSubmitColumn
                    kitId={
                        React.isValidElement(params.rowData[0])
                            ? (params.rowData[0].props.children as string)
                            : (params.rowData[0] as string)
                    }
                />
            );
        },
    },
];

const MainContentList = () => {
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });
    const { kitsReadyToAddTests, batchesToDisplay, isBatchOnlySubmit } = useSamplingEvent();

    useEffect(() => {
        const data = kitsReadyToAddTests.map(kit => {
            return [kit.id, kit.name, formattedKitBarcode(kit.barcode), kit.habitat, 'Add Tests', 'Tests', ''];
        });
        setTableState({ ...tableState, data });
    }, []);

    if (!tableState.data) {
        return <Loader />;
    }

    const toggle = (
        <div className='pl-8 flex gap-4 items-center overflow-auto'>
            <div>{batchesToDisplay ? 'Remaining Samples' : 'Samples'}</div>
            <div>
                <SamplingEventBatchStatusIndicator status={SamplingEventBatchStatus.DEFAULT} />
            </div>
        </div>
    );

    const tableClassNames = classNames('flex-1 bg-white rounded-md mt-2', styles.table);
    const table = (
        <DataTable state={tableState} columns={columns} emptyStateMessage={'No samples to display.'} className={tableClassNames} />
    );

    const updatedColumns = columns.map(column => {
        if (column.columnId === 'tests') {
            // Exclude 'renderer' by spreading only required properties
            return { ...column, renderer: undefined };
        }
        return column;
    });

    const batchComponents = batchesToDisplay?.map(
        (batch, index) =>
            batch.kits.length > 0 && (
                <div key={batch.id} className={`flex flex-col ${index !== 0 ? 'mt-4' : ''}`}>
                    <SamplingEventBatch
                        key={batch.id}
                        batchId={batch.id}
                        showDataControl={false}
                        columns={updatedColumns}
                        isExpanded={isBatchOnlySubmit}
                        showBatchInfo={false}
                        showPendingStatus={true}
                    />
                </div>
            )
    );

    const content = (
        <div className='flex flex-col flex-1 mt-4'>
            <div>{batchComponents}</div>
            {!isBatchOnlySubmit && (
                <div className='mt-4'>
                    <ExpandableSection
                        isExpanded={true}
                        classNames={{
                            wrapper: 'flex-1',
                            toggle: 'left-[25px] top-[6px] right-auto',
                        }}
                        expandedContent={
                            <div>
                                {toggle}
                                {table}
                            </div>
                        }
                        collapsedContent={toggle}
                    />
                </div>
            )}
        </div>
    );

    return <div className='flex flex-1 overflow-y-auto'>{content}</div>;
};

export default AddTestsToSample;
