import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { SamplingEventBatchStatus, SamplingKit } from 'src/shared/types';
import { downloadTextAsFile } from 'src/shared/helpers/downloadAsFile';
import useProjectSummary from 'src/shared/hooks/useProjectSummary';
import useSamplingCadence from 'src/app/samples/hooks/useSamplingCadence';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { formatSamplingEventCode } from 'src/app/samples/samplesHelpers';
import { statusConfig as batchStatusConfig } from 'src/shared/components/sampling-event-batch-status-indicator/SamplingEventBatchStatusIndicator';
import { statusConfig as eventStatusConfig } from '../components/project-sample-manifest-status-indicator/ProjectSampleManifestStatusIndicator';

type TestCount = {
    count: number;
    assayName: string;
    habitatName: string;
};

const useBatchDownload = () => {
    const { samplingEvent, getBatchById, getKitsByBatchId } = useSamplingEvent();
    const { projectSummary, customerId } = useProjectSummary();
    const { samplingCadence, customerData } = useSamplingCadence();
    const [searchParams] = useSearchParams();

    const downloadBatchCsv = async (batchId: string, batchNumber: number, samplingKit: SamplingKit[], totalCompleteSamples: number) => {
        const selectedSamplingEventId = searchParams.get('samplingEventId') as string;
        const currentEventIndex = samplingCadence?.events.findIndex(event => event.identifier === selectedSamplingEventId);
        if (currentEventIndex === undefined || currentEventIndex < 0) {
            return;
        }
        const currentBatch = getBatchById(batchId);
        const currentEvent = samplingCadence?.events[currentEventIndex];
        const eventStatusInfo = projectSummary
            ? `${eventStatusConfig[projectSummary.sampleManifestStatus].title} ${
                  eventStatusConfig[projectSummary.sampleManifestStatus].description
              }`
            : '';
        const estimatedSampleCounts = samplingEvent?.sampleManifestInfo.countInfo.kitsCount;
        const sampleCountForTestTypes = samplingEvent?.sampleManifestInfo.countInfo.countByTestTypes || [];
        const batchStatusInfo = batchStatusConfig[currentBatch?.status || SamplingEventBatchStatus.CREATED].title.replaceAll(',', '');

        const getTotalTestInfo = (): string => {
            const allTestTypes = getKitsByBatchId(batchId).flatMap(kit => kit.testTypes);

            // Group and count tests
            const counts = allTestTypes.reduce(
                (acc, test) => ({
                    ...acc,
                    [test.habitatAssayKey]: {
                        count: (acc[test.habitatAssayKey]?.count || 0) + 1,
                        assayName: test.assayName,
                        habitatName: test.habitatName,
                    },
                }),
                {} as Record<string, TestCount>
            );

            // Format results
            return Object.entries(counts)
                .map(([key, { count, habitatName, assayName }]) => {
                    const total = sampleCountForTestTypes.find(s => s.type.habitatAssayKey === key)?.count || 0;
                    return `${habitatName} ${assayName} - ${count}/${total}`;
                })
                .join('; ');
        };

        // Add Project and Batch details columns
        const csvData: (string | number)[] = [
            `Project code,${projectSummary?.projectCode}`,
            `Project name,${projectSummary?.projectName}`,
            `Customer code,${customerId}`,
            `Customer name,${customerData?.customerName}`,
            `Sampling event,"Year ${currentEvent?.year}, Event ${currentEventIndex + 1}"`,
            `Sampling event name,${currentEvent?.name}`,
            `Event status,${eventStatusInfo}`,
            `Event PIN code,${currentEvent ? formatSamplingEventCode(currentEvent?.identifier) : ''}`,
            `Total batch samples submitted out of total for event,\t${currentBatch?.kits.length}/${estimatedSampleCounts}`,
            `Total tests added to batch out of total for event,\t${getTotalTestInfo()}`,
            `Batch number,${batchNumber}`,
            `Batch ID,${currentEvent?.identifier}-B${batchNumber}`,
            `Number of complete samples in batch,${totalCompleteSamples}`,
            `Batch submission date,${moment(currentBatch?.createdAt).format('DD MMM YYYY')}`,
            `Batch accepted date,${
                currentBatch?.logisticsApprovalAt ? moment(currentBatch?.logisticsApprovalAt).format('DD MMM YYYY') : 'N/A'
            }`,
            `Current batch status,${batchStatusInfo}`,
            ',',
            ',',
        ];

        // Add Sampling Kit details header
        csvData.push(
            [
                'Sample Id',
                'Sample Name',
                'Kit Barcode',
                'Sampler',
                'Sampling Date',
                'Sampling Time',
                'Latitude',
                'Longitude',
                'Habitat',
                'Volume filtered (mL)',
                'Combined Volume Filtered (mL)',
                'Tests',
                'Field Notes',
                'Collection method',
                'Field data status',
            ].join(',')
        );

        // Add Sampling Kit details
        samplingKit.forEach(kit => {
            csvData.push(
                [
                    kit.id,
                    kit.name,
                    kit.barcode,
                    kit.sampler,
                    moment(kit.sampledAt).format('DD MMM YYYY'),
                    kit.sampledTime ? moment(kit.sampledTime).format('hh:mm A') : 'Not provided',
                    kit.latitude,
                    kit.longitude,
                    kit.habitat,
                    kit.volumeFiltered || 'N/A',
                    kit.combinedVolumeFiltered || 'N/A',
                    `"${kit.testTypes.map(test => `${test.habitatName} ${test.assayName}`).join(', ')}"`,
                    `"${kit.notes || 'Not provided'}"`,
                    kit.collectionMethod || 'N/A',
                    kit.status,
                ].join(',')
            );
        });

        const downloadFileName = [
            'NM-DSM-',
            projectSummary?.projectCode,
            '-',
            selectedSamplingEventId,
            '-',
            batchId,
            '.',
            moment().format('DDMMYY'),
            '.csv',
        ].join('');

        downloadTextAsFile(csvData.join('\n'), downloadFileName);
    };

    return { downloadBatchCsv };
};

export default useBatchDownload;
