import { ReactElement } from 'react';

export enum AppViews {
    CUSTOMERS = 'customers',
    PROJECTS = 'projects',
    SURVEY = 'survey',
    ADMIN = 'admin',
    MAP = 'map',
    DASHBOARD = 'dashboard',
    INSIGHTS = 'insights',
    TABLES = 'tables',
    LOGIN = 'login',
    SAMPLES = 'samples',
}

export enum AdminViews {
    USER_MANAGEMENT = 'userManagement',
}

export enum ProjectViews {
    PROJECT_LIST = 'projects',
    PROJECT_ADMIN = 'projectAdmin',
    NEW_PROJECT_DEFINITION = 'newProjectDefinition',
    PROJECT_DEFINITION = 'projectDefinition',
}

export enum SamplesViews {
    SAMPLES = 'samples',
    SAMPLE_MANIFEST = 'sampleManifest',
    SAMPLE_RECEPTION = 'sampleReception',
    SAMPLE_BATCH_PRINT = 'sampleBatchPrint',
}

export enum SampleManifestViews {
    START_PAGE = 'startPage',
    FORM = 'form',
    VIEW = 'view',
}

export enum SampleReceptionViews {
    HOME = 'sampleReceptionHome',
    VIEW_SAMPLE_MANIFEST = 'viewSampleManifest',
}

export enum SurveyViews {
    SURVEY_ADMIN = 'surveyAdmin',
    NEW_SURVEY_DESIGN = 'newSurveyDesign',
    SURVEY_DESIGN_SUMMARY = 'surveyDesignSummary',
    HABITAT_INSIGHTS_UPLOAD = 'habitatInsightsUpload',
}

export enum InsightViews {
    ECOLOGICAL_TRENDS = 'ecologicalTrends',
    STATISTICAL_COMPARISON = 'statisticalComparison',
    TAXONOMIC_COMPOSITION = 'taxonomicComposition',
    COMMUNITY_COMPARISON = 'communityComparison',
    SAMPLE_COMPARISON = 'sampleComparison',
    HABITAT_INSIGHTS_ECOLOGICAL_TRENDS = 'habitatInsightsEcologicalTrends',
}

export enum MapViews {
    SAMPLES = 'samples',
    HABITAT_INSIGHTS = 'habitatInsights',
}

export enum TableViews {
    SAMPLE_METRICS = 'sampleMetrics',
    HABITAT_INSIGHTS_METRICS = 'habitatInsightsMetrics',
}

export enum LoginViews {
    FORGOT_PASSWORD = 'forgotPassword',
    VERIFY_CUSTOM_CHALLENGE = 'verifyCustomChallenge',
    VERIFY_PHONE_NUMBER = 'verifyPhoneNumber',
    SET_NEW_PASSWORD = 'setNewPassword',
    TERMS_OF_USE = 'termsOfUse',
    TERMS_OF_USE_DECLINED = 'termsOfUseDeclined',
    SIGNIN = 'signin',
}

export type IndividualRouteSetting = {
    name: string;
    path: string;
    handler: ReactElement;
};

export type AppRoutesAvailability = { [key in AppViews]: boolean };
export type InsightRoutesAvailability = { [key in InsightViews]: boolean };
export type MapRoutesAvailability = { [key in MapViews]: boolean };
export type AdminRoutesAvailability = { [key in AdminViews]: boolean };
export type ProjectRoutesAvailability = { [key in ProjectViews]: boolean };
export type SurveyRoutesAvailability = { [key in SurveyViews]: boolean };
export type TableRoutesAvailability = { [key in TableViews]: boolean };
export type SamplesRoutesAvailability = { [key in SamplesViews]: boolean };

export const appRoutesAvailability: AppRoutesAvailability = {
    customers: false,
    projects: false,
    survey: false,
    admin: false,
    map: false,
    dashboard: false,
    insights: false,
    tables: false,
    login: true,
    samples: true,
};

export const adminRoutesAvailability: AdminRoutesAvailability = {
    userManagement: false,
};

export const mapRoutesAvailability: MapRoutesAvailability = {
    samples: false,
    habitatInsights: false,
};

export const projectRoutesAvailability: ProjectRoutesAvailability = {
    projects: true,
    projectAdmin: false,
    newProjectDefinition: false,
    projectDefinition: false,
};

export const surveyRoutesAvailability: SurveyRoutesAvailability = {
    surveyAdmin: false,
    newSurveyDesign: false,
    surveyDesignSummary: false,
    habitatInsightsUpload: true,
};

export const insightRoutesAvailability: InsightRoutesAvailability = {
    ecologicalTrends: true,
    habitatInsightsEcologicalTrends: true,
    statisticalComparison: true,
    taxonomicComposition: true,
    communityComparison: true,
    sampleComparison: true,
};

export const tableRoutesAvailability: TableRoutesAvailability = {
    sampleMetrics: true,
    habitatInsightsMetrics: true,
};

export const samplesRoutesAvailability: SamplesRoutesAvailability = {
    samples: true,
    sampleManifest: true,
    sampleReception: true,
    sampleBatchPrint: true,
};
